<template>
  <div>
    <VideoRecorder
      :videoSrc="videoSrc"
      :userHidden="userHidden"
      :isAmbassador="!isProspect"
      @update="onUpdate"
      @complete="isComplete = $event"
      @dirty="isDirty = $event" />
    <StepperButtons
      :canComplete="isComplete"
      :isSaving="isSaving"
      @continue="onContinue"
      @skip="$emit('complete')" />
  </div>
</template>

<script>
  import { mapState, mapGetters } from "vuex";
  import {
    getUserVideoStories,
    createUserVideoStory,
    updateUserVideoStory,
    deleteUserVideoStory,
  } from "@/services/api.service.js";
  const api = {
    getUserVideoStories,
    createUserVideoStory,
    updateUserVideoStory,
    deleteUserVideoStory,
  };
  import StepperButtons from "@/components/common/stepper/StepperButtons.vue";
  import VideoRecorder from "@/components/VideoRecorder.vue";

  export default {
    name: "StepWatchMyStory",
    components: {
      StepperButtons,
      VideoRecorder,
    },
    data() {
      return {
        videoSrc: null,
        videoRecorderData: null,
        isComplete: false,
        isDirty: false,
        videoStoryId: null,
        userHidden: null,
        deleteVideo: false,
        isSaving: false,
      };
    },
    computed: {
      ...mapState(["uid"]),
      ...mapGetters(["isProspect"]),
    },
    async mounted() {
      const userStories = (await api.getUserVideoStories(this.uid)).data.sort(
        (videoA, videoB) =>
          new Date(videoB.updatedAt) - new Date(videoA.updatedAt)
      );
      const userStory = userStories.length > 0 ? userStories[0] : null;
      this.userHidden = userStory?.userHidden ?? false;
      this.videoSrc = userStory?.video?.mp4Url ?? null;
      this.videoStoryId = userStory?._id ?? null;
    },
    methods: {
      onUpdate(value) {
        this.videoRecorderData = value;
        this.userHidden = value.userHidden;

        if (value.delete) {
          this.deleteVideo = true;
          this.videoSrc = null;
        }
      },
      async onContinue() {
        try {
          if (this.isDirty) {
            this.isSaving = true;

            if (this.videoRecorderData.videoData) {
              const formData = new FormData();
              formData.append("file", this.videoRecorderData.videoData);
              formData.append("userHidden", this.videoRecorderData.userHidden);
              await api.createUserVideoStory(formData);
            } else if (this.deleteVideo && this.videoStoryId) {
              await api.deleteUserVideoStory(this.videoStoryId);
            } else {
              await api.updateUserVideoStory(
                this.videoStoryId,
                this.videoRecorderData.userHidden
              );
            }
          }
          this.$emit("complete");
        } catch (error) {
          this.$toast.error(this.$lang.stepWatchMyStory_Error);
        } finally {
          this.isSaving = false;
        }
      },
    },
  };
</script>
